.next-mol-button, .submit-button, .start-button {
    padding: 8px;
    border-radius: 30px;
    margin: 10px;
    width: 200px;
    font-size: 18px;
    background-color: #ffcc00;
    color: black;
    box-shadow: 1px 1px 1px;
}

.next-mol-button:hover, .submit-button:hover, .start-button:hover {
    opacity: 0.8 2s ease-in-out;
    background-color: white;
    color: black;

}

.instructions {
    background:none;
    border:none;
    font-size: 1em;
    /* align-self: flex-end; */
}


.instructions:hover {
    background:none;
    color: white;
}

.tutorial-button {
    font-size: 2rem;
    background: none;
    border: none;
}

.tutorial-button:hover {
    /* background-color: black; */
    background-color: #22577A;
    color: white;
    opacity: 0.8 2s ease-in-out;
}
