@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;200&family=Fjalla+One&family=Yeseva+One&display=swap');

/* .description {
    display: inline-block;
    line-height: 1.5em;
    vertical-align: middle;

}    */

/* .background {
    background-color: #F6F6F6;
} */

figure {
    font-size: calc(12px + .5vw);
}

/* body-minus-nav */
.about-us-container {
    display: flex;
    flex-direction:column;
    margin-left:15vw;
    margin-right:15vw;
    margin-top: 20px;
}

.bio-container {
    display: flex;
    flex-direction:row;
    align-items: center;
    /* padding: calc(20px + 2vw); */
    margin-top: 10px;
    
}

.bio-container:nth-child(2n-1) {
    background: linear-gradient(#F6F6F6, #F6F6F6) padding-box,
                linear-gradient(to right top, rgb(182, 244, 146), rgb(51, 139, 147) 35%, rgba(255, 255, 255, 0) 40%) border-box;
    border: 0.5vw solid transparent;
    border-radius: 50px;
}

.bio-container:nth-child(2n) {
    background: linear-gradient(#F6F6F6, #F6F6F6) padding-box,
                linear-gradient(to left top, rgb(182, 244, 146), rgb(51, 139, 147) 35%, rgba(255, 255, 255, 0) 40%) border-box;
    border: 0.5vw solid transparent;
    border-radius: 50px;
}

.developer-pics {
    object-fit:cover;
    width: 70%;
    min-width: 200px;
    max-width: 15vw;
    border-radius: 50px;
    margin-right: 15px;
    margin-top: 10px;

}

@media only screen and (min-width: 1100px) {
    .text-description-pair {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
    }  
}

.main-heading {
    font-family: 'Yeseva One', sans-serif;
    font-size: 5em;
    margin: 0;
    padding-top: 20px;
    /* margin-top: 1em; */
    margin-bottom: 1em;
    text-align: center;
} 

/* .about-us-container  {
    margin: 0;
    font-family: helvetica; 
    display: grid;
    grid-template-columns: 1fr;
    color: gray;
    font-weight: 300;   
    align-items: center; 
    justify-items: center;
    background-color: #121212;
    font-size: calc(12px + .5vw);
    

} */



/* .bios {
    width: 33%;
}  */
/* 
.names {
    text-align: center;
}  */

/* .bio-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
} */


/* 
.developer-pics{
    width: auto;
    opacity: 90%;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 25px;
}  */


 /* grid-gap: 5px; */
    /* grid-template-rows: .20fr 1fr .15fr; */
    /* background-color: peachpuff; */