/* *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
} */

.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90px;
    background-color: #22577A;
    color: white;

}

.nav-links{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 70%;
    
}

.nav-links a:hover {
    text-shadow: 0px 4px 10px rgba(179, 147, 211, 0.8);
    opacity: 0.8 2s ease-in-out;
    text-decoration: underline;
    color: white;
}

.home,
.aboutus{
    text-decoration: none;
    color: white;
    font-size: 18px;
    padding: 5px 5px;
    margin: 0 5px;
    cursor: pointer;
}

.mobile-menu-icon{
    display: none;
}

@media only screen and (max-width: 700px) {
    .logo{
        display: flex;
        position:absolute;
        top: 15px;
        left: 35px;
    }
    .nav-links {
        display: none;
    }

.nav-links-mobile{
    position: absolute;
    display: block;
    list-style: none;
    background-color: #00828c;
    left: 0;
    top: 75px;
    transition: all 0.5s ease-out;
    width: 100%;
    }

.home,
.aboutus{
    color: white;
    text-align: center;
    padding: 32px;;
    width: 100%;
    transition: all 0.5s ease;
}

.mobile-menu-icon{
    display: none;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: #00828c;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
    }
}

#logo-name {
    font-size: 40px;
}
