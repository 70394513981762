.formula-header {
    border: 1px solid black;
    /* padding-left: 20px;
    padding-right: 20px; */
    /* padding: 5px 15px; */
    text-align: center;
    border-radius: 2px;;
}

.formula-header h1 {
    margin-top: 0;
    /* padding: 20px; */
    padding: 10px 20px;
}

.header-container {
    color: #fff;
    background-color: #22577A;
    padding: 0;
    margin: 0;
}

