.container {
    /* margin: 40px; */
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    width: 15vw;
  }
  .content {
    align-items: center;
    width: 400px;
  }
  .progressbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-top: 60px;
  }
  .progressbar::before {
    content: "";
    position: absolute;
    background-color: #3498db;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 5px;
    height: 4px;
    width: 5%;
    z-index: -1;
    transition: 0.4s ease;
  }
  .progress {
    position: absolute;
    /* background-color: #3498db; */
    background-color: #22577A;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 5px;
    height: 4px;
    width: 50%;
    z-index: -1;
    transition: 0.4s ease;
  }
  .circle {
    background-color: #fff;
    /* color: #999; */
    color: #36454F;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #e0e0e0;
    transition: 0.4s ease;
  }
  .circle.active {
    /* border-color: blue; */
    /* border-color: #FFCC00; */
    /* border-color: #72D6C9; */
    /* background-color: #72D6C9; */
    background-color: white;
    /* color: black; */
  }

  .circle.green {
    /* background-color: green; */
    border-color: rgb(6, 187, 6);
    color: black;
  }

  .circle.red {
    /* background-color: red; */
    border-color: red;
    color: black;
  }
  
  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px;
    padding-top: 20px; */
  }
  .btn {
    padding: 5px 20px;
    font-weight: bold;
    background-color: orange;
    outline-style: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    border: 0;
    margin: 5px;
    border-radius: 5px;
  }
  .btn.active {
    transform: scale(0.98);
  }
  .btn.focus {
    outline: none;
  }
  .btn.disabled {
    background-color: #999;
    cursor: not-allowed;
  }
  