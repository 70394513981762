.instructions {
    /* border-radius: 5px; */
    /* background-color: #22577A; */
    color: black;
    
}

button:hover {
background-color: white;
color: black;
cursor: pointer;
} 

.tutorial-modal {
position: absolute;
z-index: 1;
width: 100%;
height: 70%;
/* height: 100%; */
background-color: rgba(0, 0, 0, 0.25);
}

.tutorial-modal-content {
/* background-color: white; */
position: absolute;
/* top: 3%; */
/* left: 10%; */
width: 100%;
height: 100%;
padding: 20px;
border-radius: 5px;
border: 2px solid black;
}

.close {
color: Black;
float: right;
}

.close:hover {
color: #22577A;
cursor: pointer;
} 

.periodicTable{
    width: 1000px;
}

.popUpContainer{
    display: flex;
    justify-content: space-between;
    background-color: #22577A
}

iframe {
    height: 100%;
    width: 100%;
}