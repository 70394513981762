
* {
  box-sizing: border-box;
  font-family: 'Figtree', sans-serif;
}

body {
  background-image:url("../background.jpg");
  background-repeat:no-repeat;
  background-size:cover;
  padding:0; 
  margin:0;
  width: 100%;
  overflow-x: hidden;
}

.hide {
  display: none;
}

.main-stage-container {
  padding-left: 0;
  margin-left: 5vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 40vw;
  margin-left: -3vw;
}

.Main-container{
  font-family: 'Lucida Sans';
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 10px;
  /* background: url("./background.jpg") no-repeat center center fixed; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* grid-template-rows: 150px 1fr 50px; */
}

header {
  grid-column: span 3;
  max-width: 100vw;
  position: sticky;
  top: 0;
  z-index:1;
}

.game-buttons {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
} 
.stage-components {
  margin-right: 5%;
} 

.stage-header p {
  text-align: center;
  font-size: 40px;
} 

.Left-comp{
  display: flex;
  margin-top: 50px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 8vw;
} 

.Left-comp ul {
  margin-top: 50px;
} 

.Right-comp{
  width: 100%;
  margin: 0 auto;
}

.stage-header{
  border-top: 2px;
  text-align: center;
  padding: 20px 0 0 0;
  margin-bottom: 20px;
  
}

.short-directions {
  list-style-type: none;
}

.short-directions li {
  line-height: 1.5em;
}

.stage-msg{
  display: flex;
  flex-direction: column;
  background-color: #22577A;
  color: white;
  margin: 0;
  font-size: 20px;
  /* border-top-left-radius: 3px;
  border-top-right-radius: 3px; */
  text-align: center;
  align-self: center;
  align-items: center; 
}

.stage-container {
  max-width: 60%;
  margin: 0 auto;
  box-shadow: 1px 2px 9px black;
  background: rgba(234, 234, 234, 0.928);
  border-top: none;
  border-radius: 3px; 
  position: relative;
}

.stage-container {
  height: calc(100vh - 200px);
  width: calc(100vw - 200px);
}  

.header{
  display: flex;
} 

.restart {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* position: absolute;
  bottom: 0;  */
  border: none;
  background: transparent;
  right: 0;
  border: 2px solid purple;
}

.reset-container {
  display: flex;
  justify-content: flex-end;  /* align-items: flex-end; */
  flex-direction: row;
  padding-top: 5px;
}

/* .restart-container {
  position: absolute;
  bottom: 0; 
  right: 0; 
} */

.reset-button {
  background:none;
  border:none;
  opacity: .5;
  font-size: 1.25em;
}

.reset-button:hover {
  cursor: pointer;
  color: #22577A;
  background: none;
  /* background-color: #22577A; */
  opacity: .9;
}

#how-to-play {
  font-size: 1.5rem;
}

/* .instruction-container {
  position: fixed;
  bottom: 0;
  right: 0
} */
