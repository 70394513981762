.instructions {
    /* border-radius: 5px; */
    /* background-color: #22577A; */
    color: black;
    
}

button:hover {
background-color: white;
color: black;
cursor: pointer;
} 

.modal {
position: absolute;
z-index: 1;
width: 100%;
height: 80%;
background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
background-color: white;
position: absolute;
top: 3%;
/* left: 10%; */
width: 100%;
padding: 20px;
border-radius: 5px;
border: 2px solid black;
}

.close {
color: Black;
float: right;
}

.close:hover {
color: #22577A;
cursor: pointer;
} 

.periodicTable{
    max-width: 700px;
}

.popUpContainer{
    display: flex;
    justify-content: space-between;
    background-color: #22577A
}