

/* .reset-button {
    border-radius: 5px;
    /* background-color: grey;
    outline: none; */
   /* background-color: #22577A;
    color: white;
} */

.instructions {
    /* border-radius: 5px; */
    /* background-color: #22577A; */
    color: black;
    
}
  
button:hover {
    background-color: white;
    color: black;
    cursor: pointer;
} 
 
.instructions-modal {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50%;
    /* background-color: rgba(0, 0, 0, 0.25); */
}
  
.modal-content {
    background-color: white;
    position: absolute;
    top: 3%;
    /* left: 10%; */
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid black;
  }
  
.close {
    color: Black;
    float: right;
  }
  
  .close:hover {
    color: #22577A;
    cursor: pointer;
  } 
     