/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .stage-container {
  
  height: calc(100vh - 300px);
  width: calc(100vw - 400px);
  padding: 20px;
}  */

/* nav ul {
  margin-top: 0;
  padding: 0;
  align-items: center;
}

nav {
  width: 100%;
  margin-top: 0;
  border-bottom: 1px solid black;
  box-shadow: 1px 1px 2px black;
} */

/* nav ul {
  margin: 0;
  padding: 0x;
  display: flex;
}

nav ul li {
  display: inline-block;
  list-style-type: none;
}

nav ul li:nth-child(2) {
  margin-right: auto;
} */

/* #logo-name {
  font-size: 40px;
} */

/* .hamburger-menu {
  padding-top: 15px;;
  padding-right: 10px;
  text-align: center;;
}

#menu-header {
  margin: 0;
  font-size: 12px;
} */



